import React, {useState} from "react";
import {GSPSSwitchStyled} from "Components/Common/GSPSStyledSwitch.styles";
import {StyledLabel} from "Components/Common/GSPSLabeledInput.styles";
import {GSPSEnableDisabledContainer} from "Components/Common/GSPSStyledContainers.styles";

import PropTypes from "prop-types";

export const GSPSEnableDisableSwitch = ({
    inputId,
    defaultValue,
    register,
    isRequired = false,
}) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    return (
        <GSPSEnableDisabledContainer>
            <GSPSSwitchStyled
                checked={selectedValue}
                onClick={() => setSelectedValue(!selectedValue)}
                name={inputId}
                id={inputId}
                inputProps={{"aria-label": "controlled"}}
                {...register(
                    inputId,
                    {
                        required: isRequired && {value: true, message: "This field is required."},
                    },
                )}
            >
            </GSPSSwitchStyled>
            <StyledLabel htmlFor={inputId} className="body-md">
                {selectedValue ? "Enabled" : "Disabled"}
            </StyledLabel>

        </GSPSEnableDisabledContainer>

    );
};

GSPSEnableDisableSwitch.propTypes = {
    inputId: PropTypes.string,
    register: PropTypes.func,
    isRequired: PropTypes.bool,
    defaultValue: PropTypes.bool.isRequired,
};
