import React, {lazy, Suspense} from "react";
import {Login} from "Pages/Login/Login";
import {ForgotPassword} from "Pages/Login/ForgotPassword";
import {ResetPassword} from "Pages/Login/ResetPassword";
import {SubscriberEditor} from "Pages/Subscriber/SubscriberEditor";
import {Account} from "Pages/Accounts/Account";
import {Location} from "Pages/Location/Location";
import {LocationsManager} from "Pages/Location/LocationsManager";
import {AddLocation} from "Pages/Location/AddLocation";
import {PageNotFound} from "Pages/PageNotFound";
import {AccountsManager} from "Pages/Accounts/AccountsManager";
import {AddAccount} from "Pages/Accounts/AddAccount";
import {UserEditor} from "Pages/Users/UserEditor";
import {UserCreator} from "Pages/Users/UserCreator";
import {Report} from "Pages/Report/Report";
import {ActivityLogs} from "Pages/History/ActivityLogs";
import {SubscriberCreator} from "Pages/Subscriber/SubscriberCreator";

const Audits = lazy(() => import("Pages/Audits/Audits").then((module) => ({default: module.Audits})));
const AuditCreator = lazy(() => import("Pages/Audits/AuditCreator").then((module) => ({default: module.AuditCreator})));
const AuditViewer = lazy(() => import("Pages/Audits/AuditViewer").then((module) => ({default: module.AuditViewer})));
const PublicAuditViewer = lazy(() => import("Pages/Audits/PublicAuditViewer").then(
    (module) => ({default: module.PublicAuditViewer})));
const AuditLogs = lazy(() => import("Pages/Audits/AuditLogs").then(
    (module) => ({default: module.AuditLogs})));

const Subscribers = lazy(() => import("Pages/Subscriber/Subscribers").then(
    (module) => ({default: module.Subscribers})));
const Accounts = lazy(() => import("Pages/Accounts/Accounts").then(
    (module) => ({default: module.Accounts})));
const Locations = lazy(() => import("Pages/Location/Locations").then(
    (module) => ({default: module.Locations})));
const Users = lazy(() => import("Pages/Users/Users").then(
    (module) => ({default: module.Users})));

export const getPathElementMap = (setCurrentUser, resetCurrentUserSession) => [
    {path: "/",
        element: <Suspense fallback={"loading..."}><Audits updateCurrentUser={setCurrentUser} /></Suspense>},
    {path: "/dashboard",
        element: <Suspense fallback={"loading..."}> <Audits updateCurrentUser={setCurrentUser} />  </Suspense>},
    {path: "/audits",
        element: <Suspense fallback={"loading..."}> <Audits updateCurrentUser={setCurrentUser} /> </Suspense>},
    {path: "/audits/:auditID",
        element: <Suspense fallback={"loading..."}> <AuditViewer /> </Suspense>},
    {path: "/audits/creator",
        element: <Suspense fallback={"loading..."}> <AuditCreator /> </Suspense>},
    {path: "/audits/logs/:auditID", element: <Suspense fallback={"loading..."}> <AuditLogs /> </Suspense>},
    {path: "/public-audits/:auditID", element: <Suspense fallback={"loading..."}> <PublicAuditViewer /> </Suspense>},

    {path: "/subscribers/", element: <Suspense fallback={"loading..."}> <Subscribers /> </Suspense>},
    {path: "/subscribers/creator", element: <SubscriberCreator />},
    {path: "/subscribers/:subscriberID", element: <SubscriberEditor />},

    {path: "/accounts/", element: <Suspense fallback={"loading..."}> <Accounts /> </Suspense>},
    {path: "/accounts/add", element: <AddAccount />},
    {path: "/accounts/:accountID", element: <Account />},
    {path: "/accounts/manage", element: <AccountsManager />},

    {path: "/locations/", element: <Suspense fallback={"loading..."}> <Locations /> </Suspense>},
    {path: "/locations/:locationID", element: <Location />},
    {path: "/locations/manage", element: <LocationsManager />},
    {path: "/report", element: <Report />},
    {path: "/locations/add", element: <AddLocation />},

    {path: "/users/", element: <Suspense fallback={"loading..."}> <Users /> </Suspense>},
    {path: "/users/creator", element: <UserCreator />},
    {path: "/users/:userID", element: <UserEditor />},

    {path: "*", element: <PageNotFound />},

    {path: "/history/", element: <ActivityLogs />},
    {path: "/reset-password", element: <ResetPassword resetCurrentUserSession={resetCurrentUserSession} />},

    // Stripe stuff - ignore for now
    // {path: "/plans", element: <Plans />},
    // {path: "/return", element: <Return />},
];

export const getUnauthenticatedPathElementMap = (setHasValidRefreshToken) => [
    {path: "/login", element: <Login setHasValidRefreshToken={setHasValidRefreshToken} />},
    {path: "/forgot-password", element: <ForgotPassword />},
    {path: "/reset-password", element: <ResetPassword />},
    {path: "*", element: <Login setHasValidRefreshToken={setHasValidRefreshToken} />},
    {path: "/public-audits/:auditID", element: <PublicAuditViewer />},
    // Stripe stuff - ignore for now
    // {path: "/plans", element: <Plans />},
    // {path: "/checkout/:priceLookupKey", element: <Checkout />},
    // {path: "/return", element: <Return />},
];

export const unRedirectionPaths = [
    // Stripe stuff - ignore for now
    // "/plans",
    // "/return",
    "/reset-password",
];
