export const UserPermissionGroupIDs = {
    SUPER_ADMIN: 1,
    GROUP_ADMIN: 2,
    USER: 3,
};

export const UserIndustryTypeID = {
    CONTRACTOR: 1,
};

export const UserRoleID = {
    OTHER: 5,
};
