import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import {SnackbarProvider} from "notistack";
import GSPSNotification from "Components/Notifications/GSPSNotification";
import {store} from "app/store";
import {Provider} from "react-redux";
import * as Sentry from "@sentry/react";


if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        // tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <SnackbarProvider
            maxSnack={3}
            Components={{
                success: GSPSNotification,
                error: GSPSNotification,
            }}
        >
            <Provider store={store}>
                <App />
            </Provider>
        </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
