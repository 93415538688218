import React from "react";
import {FooterContainer} from "./GSPSFooter.styles";

const GSPSFooter = () => {
    return <FooterContainer>
        <footer className="position-absolute bottom-0 start-0 w-100 z-3">
            <div className="container d-flex justify-content-between align-items-center py-2">
                <div>
                    <h6 className="copyRights m-0">
                        © 2025 <a
                            href="https://www.sitevisionpro.com" target="_blank" rel="noopener noreferrer"
                        >
                            SiteVisionPro
                        </a>
                    </h6>
                </div>
                <div>
                    <h6 className="copyRights m-0">
                        For help contact <a href="mailto:support@sitevisionpro.com"> support@sitevisionpro.com </a>
                    </h6>
                </div>
            </div>
        </footer>
    </FooterContainer>;
};

export default GSPSFooter;
